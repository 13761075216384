import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "components/layout"
import Seo from "components/seo"
import ReactHtmlParser from "html-react-parser"
import moment from "moment"

const NewsDetailPage = ({ data }) => {
  return (
    <Layout>
      <div className="article-page">
        <div className="entry-contents">
          <div className="entry-header">
            <h1 className="title">{data.strapiOkulabHpPost.title}</h1>
            <p className="published-date">
              {moment(data.strapiOkulabHpPost.publishedAt).format(`YYYY.MM.DD`)}
            </p>
          </div>

          <div className="body">
            {ReactHtmlParser(
              data.strapiOkulabHpPost.childStrapiOkulabHpPostBodyTextnode?.body ??
                ""
            )}
          </div>
        </div>

        <div className="footer">
          <Link to="/news" className="back-to-index">
            ニュース一覧
          </Link>
        </div>
      </div>
    </Layout>
  )
}
export const Head = ({ data }) => <Seo title={`${data.strapiOkulabHpPost.title} | ニュース`} />
export default NewsDetailPage

export const query = graphql`
  query ($slug: String) {
    strapiOkulabHpPost(slug: { eq: $slug }) {
      title
      publishedAt
      slug
      childStrapiOkulabHpPostBodyTextnode {
        body
      }
    }
  }
`
